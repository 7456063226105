<template>
  <div id="exhibitor-list">

    <div class="wcpt-list">
      <div v-for="exhibitor in exhibitors" :key="exhibitor.id">
        <div
            class="web-person-divider"
            v-if="exhibitor.isLetter"
            style="flex: 0 0 100vw;width: 100vw;"
        >
          <h2>{{ exhibitor.letter }}</h2>
        </div>
        <div v-if="!exhibitor.isLetter">
          <a
              @click="showDisclaimer(exhibitor)"
              class="wcpt-list-item item item-icon-right"
              style="text-align: center; height:150px;"
          >
            <img
                v-if="exhibitor.logo"
                :src="'https://fileserver-188ab.kxcdn.com/file/' +exhibitor.logo.split('/')[4]"
                style="max-height: 80px; padding-top:5px"
            />
            <p>{{ exhibitor.short_name || exhibitor.name }}</p>
            <small v-for="location in exhibitor.locationList" :key="location.id"> <span v-if="location.name"> Visit our booth: {{location.name}} </span> </small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {addIcons} from "ionicons";
import {timeOutline} from "ionicons/icons";
import {alertController} from "@ionic/vue";
import {mapActions} from "vuex";

addIcons({"time-outline": timeOutline});
export default {
  data() {
    return {}
  },
  components: {},
  props: ['exhibitors'],
  created() {
  },
  methods: {
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async showDisclaimer(exhibitor) {
      if (exhibitor.disclaimer && exhibitor.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: exhibitor.name,
              message: '<strong>' + exhibitor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(exhibitor.name);
                    if (exhibitor.disclaimer_link) {
                      window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + exhibitor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      } else if (exhibitor.disclaimer_link && exhibitor.disclaimer_link.length > 10) {
        window.open(exhibitor.disclaimer_link, '_blank', 'location=no');
      } else {
        this.$router.push('/app/exhibitors/' + exhibitor.id);
      }

    }
  }

};
</script>
<style scoped>
a {
  text-decoration: none;
}
</style>